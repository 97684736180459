body {
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #f64f59, #c471ed, #12c2e9);
}
.App {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #12c2e9; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f64f59,
    #c471ed,
    #12c2e9
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.form-container {
  background: #fff;
  border-radius: 20px;
  padding: 30px 80px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 15px 16px 0.17px rgba(0, 0, 0, 0.05);
}

h1 {
  text-align: center;
}
.container {
  margin-top: 3%;
  margin-bottom: 3%;
}
.setup span {
  font-size: 0.8em;
  color: red;
}
.confirm {
  font-weight: bold;
  font-size: 1.2em;
}
